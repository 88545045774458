import * as React from 'react';
import Layout from '../../components/Layout';
import DocumentSelection from '../../components/DocumentSelection';

type Props = {
	// text?: string;
};

export const DocumentSelectScreen: React.FunctionComponent<Props> = () => (
	<Layout currentStep={0}>
		<DocumentSelection />
	</Layout>
);

export default DocumentSelectScreen;

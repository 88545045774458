import * as React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import DocumentSelectionItem from './DocumentSelectionItem';
import DocumentSelectionHelpers from './DocumentSelectionHelpers';
import { DocumentContext } from '../../context/DocumentContext';
import BreakpointLayout from '../BreakpointLayout/BreakpointLayout';
import SVG from '../SVG';
import useBrowserCheck from '../../hooks/useBrowserCheck';

type Props = {
	// documentUploadStatus?: IDocumentsUploadStatus;
};

const PrivacyText = () => (
	<>
		Your uploaded documents and captured actions during the ID verification process may constitute sensitive data. Please see our{' '}
		<Link href="https://bronid.com/legal/privacy" prefetch={false}>
			<a target="_blank" className="text-indigo-700">
				Privacy Policy
			</a>
		</Link>{' '}
		for information about how we store and use your personal data.
	</>
);

const DrawPageDescription = ({ state, router }) => {
	const browserMessage = useBrowserCheck();

	return (
		<>
			<h1 className="mb-4 mt-8 text-center text-xl font-semibold leading-tight text-gray-900 md:ml-6 md:text-center">Select ID Document</h1>
			{browserMessage ? <p className="mb-4 text-center text-lg text-red-400 md:mx-6 md:text-center">{browserMessage}</p> : null}
			<p className="text-md mb-4 text-center text-gray-400 md:mx-6 md:text-center">
				Provide 2 documents. At least 1 document must have your photograph.
			</p>
			<div className="hidden text-center text-sm text-gray-700 md:block md:text-left">
				<SVG.SelectIdType />
			</div>
			<p className="mb-12 mt-6 hidden text-center text-xs text-gray-400 md:mx-6 md:block md:text-left">
				<PrivacyText />
			</p>
			{DocumentSelectionHelpers.checkEnoughDocumentCollected(state) && (
				<button
					type="button"
					className="mx-6 mb-6 w-full max-w-lg self-center rounded-full bg-green-400 py-3 px-4 text-sm uppercase text-white shadow hover:bg-green-600 md:w-auto md:self-auto"
					onClick={() => {
						router.push('/liveness-test');
					}}
				>
					Go to Step 2
				</button>
			)}
		</>
	);
};

const DrawPageAction = () => {
	const { state } = React.useContext(DocumentContext);

	return (
		<div className="h-full bg-gray-100 md:px-12">
			<div className="relative px-4 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
				<div className="relative mx-auto max-w-7xl">
					<div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
						{DocumentSelectionHelpers.getDocuments(state?.documentsContext).map(DocumentSelectionItem)}
					</div>
				</div>
			</div>
			<p className="mb-2 mt-6 block text-center text-xs text-gray-400 md:hidden md:text-left">
				<PrivacyText />
			</p>
		</div>
	);
};

const DocumentSelection: React.FunctionComponent<Props> = () => {
	const router = useRouter();
	const { state } = React.useContext(DocumentContext);

	React.useEffect(() => {
		if (process.env.NODE_ENV !== 'test') {
			if (DocumentSelectionHelpers.checkEnoughDocumentCollected(state)) {
				// console.log('prefetching /liveness-test');
				router.prefetch('/liveness-test');
			} else {
				// console.log('not now...');
			}
		}
	}, [router, state]);

	return <BreakpointLayout pageDescription={<DrawPageDescription state={state} router={router} />} pageAction={<DrawPageAction />} />;
};

export default DocumentSelection;

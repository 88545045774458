import React from 'react';
// import classnames from 'classnames';
// import { useRouter } from 'next/router';

type Props = {
	pageDescription?: React.ReactElement | null;
	pageAction?: React.ReactElement | null;
	children?: React.ReactElement | null;
};
export const BreakpointLayout = ({ pageDescription, pageAction, children = null }: Props): React.ReactElement | null => (
	<div className="md:flex">
		<div className="flex w-full flex-col justify-center">{pageDescription}</div>
		<div className="md:w-2/3 md:shrink-0">{pageAction}</div>
		{children}
	</div>
);

export const BreakpointLayoutWebcam = ({ pageDescription, pageAction, children = null }: Props): React.ReactElement | null => (
	<>
		<div className="breakpointLayout">
			<div className="sidebar">{pageDescription}</div>
			<div className="action">{pageAction}</div>
			{children}
		</div>
		<style jsx>
			{`
				.breakpointLayout {
					display: grid;
					width: 100%;
					grid-gap: 10px;
					// single column
					grid-template-rows: fit-content(100px) auto;
					// 1 + 2 rows
					grid-template-areas:
						'sidebar'
						'action';
				}
				.sidebar {
					// dev
					// rgba(126, 220, 226, var(--bg-opacity))
					grid-area: sidebar;
				}
				.action {
					// dev
					// background-color: rgba(132, 225, 188, var(--bg-opacity));
					grid-area: action;
				}

				@media only screen and (min-width: 768px) {
					.breakpointLayout {
						// single row
						grid-template-columns: 1fr 2fr;
						// two columns
						grid-template-areas: 'sidebar action';
					}
				}
			`}
		</style>
	</>
);

export default BreakpointLayout;

import React from 'react';
import DocumentSelectScreen from '../screens/DocumentSelectScreen';
import NoSsr from '../components/NoSsr';

const IndexPage = (): React.ReactElement => {
	return (
		<NoSsr>
			<DocumentSelectScreen />
		</NoSsr>
	);
};

export default IndexPage;

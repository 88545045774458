import * as React from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import DocumentSelectionHelpers from './DocumentSelectionHelpers';
import { DocumentContext } from '../../context/DocumentContext';
import SVG from '../SVG';

type Props = { id: string; text: string; uploadStatus?: string; icon: React.ReactNode };

const proportions = 'h-28 lg:h-32 xl:h-36 w-full object-cover px-8';
const cardRatio = `${proportions} pt-5 pb-2`;
const a4Ratio = `${proportions} py-3`;
export const icons = (isHovered: boolean): { [key: string]: React.ReactNode } => ({
	passport: isHovered ? <SVG.Passport02 className={cardRatio} /> : <SVG.Passport01 className={cardRatio} />,
	driver_licence: isHovered ? <SVG.DriverLicence02 className={cardRatio} /> : <SVG.DriverLicence01 className={cardRatio} />,
	national_id_card: isHovered ? <SVG.NationalIdCard02 className={cardRatio} /> : <SVG.NationalIdCard01 className={cardRatio} />,
	student_id_card: isHovered ? <SVG.NationalIdCard02 className={cardRatio} /> : <SVG.NationalIdCard01 className={cardRatio} />,
	change_of_name: isHovered ? <SVG.ChangeOfName02 className={a4Ratio} /> : <SVG.ChangeOfName01 className={a4Ratio} />,
	medicare: isHovered ? <SVG.Medicare02 className={cardRatio} /> : <SVG.Medicare01 className={cardRatio} />,
	birth_certificate: isHovered ? <SVG.BirthCertificate02 className={a4Ratio} /> : <SVG.BirthCertificate01 className={a4Ratio} />,
	bank_statement: isHovered ? <SVG.OtherDocument02 className={a4Ratio} /> : <SVG.OtherDocument01 className={a4Ratio} />,
	utility_bill: isHovered ? <SVG.OtherDocument02 className={a4Ratio} /> : <SVG.OtherDocument01 className={a4Ratio} />,
});

const getUploadStatus = (state, props) => {
	const uploadStatus = state?.documentsContext?.documents?.[props.id]?.uploadStatus ?? DocumentSelectionHelpers.UPLOAD_STATUS.NONE;
	const uploadStatus_Completed = uploadStatus === DocumentSelectionHelpers.UPLOAD_STATUS.COMPLETED;
	const uploadStatus_InProgress = uploadStatus === DocumentSelectionHelpers.UPLOAD_STATUS.IN_PROGRESS;
	const uploadStatus_Error = uploadStatus === DocumentSelectionHelpers.UPLOAD_STATUS.ERROR;
	const uploadStatus_Other =
		uploadStatus !== DocumentSelectionHelpers.UPLOAD_STATUS.COMPLETED &&
		uploadStatus !== DocumentSelectionHelpers.UPLOAD_STATUS.IN_PROGRESS &&
		uploadStatus !== DocumentSelectionHelpers.UPLOAD_STATUS.ERROR;
	return { uploadStatus_Completed, uploadStatus_InProgress, uploadStatus_Error, uploadStatus_Other };
};

const DocumentSelectionItem: React.FunctionComponent<Props> = (props, key) => {
	const { state } = React.useContext(DocumentContext);
	const { uploadStatus_Completed, uploadStatus_InProgress, uploadStatus_Error, uploadStatus_Other } = getUploadStatus(state, props);

	const isNotUsed = !uploadStatus_Other;
	const [isHovered, setIsHovered] = React.useState(isNotUsed);
	const updateHovered = hoverState => {
		if (uploadStatus_Other) {
			setIsHovered(hoverState);
		}
	};

	const { text, id } = props;
	const divClasses = classnames({
		'flex flex-col rounded-lg shadow-lg overflow-hidden': true,
		'bg-white square': true,
		'text-gray-700 bg-primary': uploadStatus_Completed,
		'text-gray-700 bg-white': uploadStatus_InProgress,
		'text-gray-700 bg-red-400': uploadStatus_Error,
		'text-gray-700 bg-gray-200 hover:bg-white': uploadStatus_Other,
	});
	const textClasses = classnames({
		'text-xs leading-5 font-medium uppercase underline': true,
		'text-green-500 hover:text-green-500 focus:text-green-500': uploadStatus_Completed,
		'text-red-500 hover:text-red-500 focus:text-red-500': uploadStatus_Error,
		'text-gray-500 hover:text-gray-600 group-hover:text-gray-600 focus:text-gray-700 group-focus:text-gray-700':
			uploadStatus_Other || uploadStatus_InProgress,
	});

	return (
		<React.Fragment key={key}>
			<Link href="/document-verification/[doc_id]" as={`/document-verification/${id}`}>
				<a onMouseEnter={() => updateHovered(true)} onMouseLeave={() => updateHovered(false)}>
					<div className={divClasses}>
						<div className="shrink-0">{icons(isHovered)[id]}</div>
						<div className="flex flex-1 flex-col justify-between pb-4">
							<div className="flex-1 text-center">
								<p className={textClasses}>{text}</p>
							</div>
						</div>
					</div>
				</a>
			</Link>
			<style jsx>{`
				.square {
					border: 1px solid #e6e7ec;
					border-radius: 3px;
				}
				.square:hover {
				}
			`}</style>
		</React.Fragment>
	);
};

export default DocumentSelectionItem;
